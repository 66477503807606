<template>
   
        <div class="item-p">
            <chart
            :options="line"
            :init-options="initOptions"
            ref="line"
            autoresize
            />
        </div>
   
</template>
<script>
import ECharts from '@/components/echarts/ECharts.vue'


export default {
    components: {
        chart: ECharts
    },
   props:{
        //[
	//{legend:"推荐量",color:"#34243",series:[{name:"1",value:123},{name:"2",value:12321}]},
	//{legend:"阅读量",color:"#34243",series:[{name:"1",value:123},{name:"2",value:12321}]},
    //]
        data: {
            type:Array,
            default:[]
        },
        title: {
            type:String,
            default:''
        }
    },
    watch:{
        data (newVal, oldVue) {
           this.init()
        }
    },
    data(){
        return {
             line:{
                title: {
                    text: this.title,
                    x: 'center',
                    textStyle:{
                        color: '#00A2FF',
                        fontSize: 14
                    },
                    left: -20
                },
                // tooltip: {
                //     trigger: 'axis'
                // },
                legend: {
                    data:[]
                },
                grid: {
                    left: "0%",
                    right: "0%",
                    bottom: "0%",
                    top: "36%",
                    containLabel: true
                },
                // toolbox: {
                //     feature: {
                //         saveAsImage: {}
                //     }
                // },
                xAxis: {
                    type: 'category',
                    // boundaryGap: false,
                    data: [],
                    axisTick: {
                        show: false,
                        alignWithLabel: true,
                        lineStyle: {
                            color: "#fff"
                        }
                    },
                    axisLabel: {
                        //字体颜色
                        textStyle: {
                            //textStyle里面写x轴下的字体的样式
                            color: "#B1CEF4",
                            fontSize: 10
                        }
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#01DFF4"
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        //字体颜色
                        textStyle: {
                            //textStyle里面写x轴下的字体的样式
                            color: "#01DFF4",
                            fontSize: 10
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            type: 'dotted',
                            color: '#B1CEF4',
                            width: 1,
                            opacity: 0.3
                        }
                    }
                },
                series: [
                    {
                        // name:'邮件营销',
                        type: 'line',
                        // stack: '总量',
                        data: [],
                        itemStyle: {
                            normal: {color:'#00F9FF', label: {show: true}}
                        }
                    }
                ]
            },
            initOptions: {
                renderer: 'canvas'
            }
        }
    },
    methods: {
        init() {
            var list = []
            var xAxisData = []
            for (var item of this.data) {
                var value = item.value
                var name = item.name
                list.push(value)
                xAxisData.push(name)
            }
            this.line.series[0].data = list
            this.line.xAxis.data = xAxisData
        }
    },
    mounted(){
        this.init()
    }
}
</script>
<style scoped>
.item-p{
    width: 100%;
    height: 100%;
}
</style>